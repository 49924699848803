var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[(_vm.productDetail?.data?.coupon)?_c('el-dialog',{staticStyle:{"height":"80%","margin":"auto"},attrs:{"title":_vm.$t('consume.selectCoupon'),"visible":_vm.dialogFormVisible,"before-close":_vm.handleClose,"center":"","width":_vm.$isMobile ? '90%' : '40%'},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[(_vm.productDetail.data.coupon.length > 0)?_c('div',{staticClass:"coupon"},_vm._l((_vm.productDetail.data.coupon),function(item,index){return _c('div',{key:item.coupon_id,staticClass:"_coupon_item",class:{
          _coupon_check: _vm.checkNum === index,
          _coupon_item_disable:
            (!item.channel_type_arr.includes(_vm.couponName) &&
              item.title !== 'Coin') ||
            item.limit_time > 0 ||
            (item.usage_num_daily > 0 && item.usage_num_daily_count <= 0),
        },on:{"click":function($event){return _vm.couponCheck(item, index)}}},[_c('div',{staticClass:"_coupon_box"},[_c('div',{staticClass:"_coupon_box_top"},[_c('div',{staticClass:"_coupon_center"},[_c('div',{staticClass:"_coupon_title"},[_c('span',[_vm._v(_vm._s(item.title))]),(item.usage_num > 1 && item.usage_num_daily === 0)?_c('span',{staticClass:"coupon_usage_num"},[_vm._v(" ("+_vm._s(_vm.$t("consume.remainingNum", { num: item.usage_num }))+") ")]):(
                    item.usage_num_daily > 0 && item.usage_num_daily_count > 0
                  )?_c('span',{staticClass:"coupon_usage_num"},[_vm._v(" ("+_vm._s(_vm.$t("consume.limitNum", { num: item.usage_num_daily_count, }))+") ")]):_vm._e()]),_c('div',{staticClass:"_coupon_date"},[_c('div',{staticClass:"_coupon_date_text"},[_vm._v(" "+_vm._s(item.coupon_date_text ? _vm.$t("consume.validityPeriod") : _vm.$t("consume.availableDates"))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(item.date_text)}})]),_c('div',{staticClass:"_coupon_box_footer"},[_c('div',{staticClass:"_coupon_box_bottom",class:{
                    _coupon_box_bottom_active: item.channelShow,
                  }},[(item.limit_time > 0)?_c('div',{staticClass:"_coupon_box_timing"},[_vm._v(" "+_vm._s(_vm.$t("consume.use"))+" "),_c('span',{class:'_coupon_box_timing_val' + index},[_vm._v(" "+_vm._s(_vm.getTimeS(item.limit_time))+" ")])]):_vm._e()]),_c('div',{staticClass:"_coupon_coin"},[_vm._v(" "+_vm._s(_vm.$t(`consume.deliver${item.type}`, { money: item.order_coin, gift: item.type === "2" ? item.rebate : item.give_coin, }))+" ")])]),_c('div',{staticClass:"_coupon_sjx",on:{"click":function($event){$event.stopPropagation();return _vm.couponPayTypeShow(item)}}},[_c('img',{attrs:{"src":require("../assets/img/xem.png")}})])]),_c('div',{staticClass:"_coupon_right"},[_c('img',{staticClass:"_coupon_check_img",attrs:{"src":require("../assets/img/check.png"),"alt":""}})]),_c('div',{staticClass:"_coupon_item_explain",style:({
                display:
                  (!item.channel_type_arr.includes(_vm.couponName) &&
                    item.title !== 'Coin') ||
                  +item.limit_time > 0 ||
                  (item.usage_num_daily > 0 &&
                    item.usage_num_daily_count <= 0)
                    ? 'block'
                    : 'none',
              }),on:{"click":function($event){return _vm.handleExplain(item)}}})])])])}),0):_c('div',[_vm._v(_vm._s(_vm.$t("consume.notUseCoupon")))]),_c('el-button',{staticStyle:{"width":"100%","background":"rgba(17, 136, 255)","border-color":"transparent","margin-top":"20px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("Login.confirm")))])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }